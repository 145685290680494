import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"

const servicios = [
  {
    title: "Estudios de Perfiles del Suelo y Napas",
    image: "images/foto1.jpg",
    content:
      "Construimos pozos profundos en todos los diámetros. atendemos desde la 4a a la 10a región. Realice una buena inversión, deje su proyecto o necesidad en manos de expertos.",
  },
  {
    image: "images/foto2.jpg",
    title: "Perforación de Pozos Profundos",
    content:
      "Construimos pozos profundos en todos los diámetros. atendemos desde la 4a a la 10a región. Realice una buena inversión, deje su proyecto o necesidad en manos de expertos.",
  },
  {
    image: "images/foto3.jpg",
    title: "Mantenimiento de Pozos",
    content:
      "Construimos pozos profundos en todos los diámetros. atendemos desde la 4a a la 10a región. Realice una buena inversión, deje su proyecto o necesidad en manos de expertos.",
  },
]

export function Servicio({ data, html }) {
  console.log(data)
  const centered = data.center ? data.center : false
  return (
    <StyledServicio center={centered}>
      {servicios.map(s => (
        <DivServicio key={s.title} {...s} />
      ))}
    </StyledServicio>
  )
}

const DivServicio = ({ image, title, content }) => (
  <StyledUnServicio>
    <img src={image} />
    <div className="text-servicio">
      <div className="title">{title}</div>
      <div>{content}</div>
    </div>
  </StyledUnServicio>
)

const StyledUnServicio = styled.div`
  margin-bottom: 3em;
  border: solid 1px #ccc;
  padding: 0.25em;
  div.title {
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }
  div.text-servicio {
    padding: 0.5em 1em 2em;
  }
`

const StyledServicio = styled.div`
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

export const ServicioBlock = {
  label: "Servicio",
  name: "content",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
  },
  fields: [
    { name: "content", label: "Servicio", component: "markdown" },
    { name: "center", label: "Center", component: "toggle" },
  ],
}
